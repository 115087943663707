import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import axios from 'axios'
import './training.css'
import '../home/Home.css'
import { courses } from './courses';
import { Link } from 'react-router-dom';
import logo from '../home/descarga.png'

type TrainingProps = {}

const Training: FC<TrainingProps> = (props) => {
  const { } = props
  const { t } = useTranslation();

  return (
    <div className="training">

      <h1 className="home-title">{t('Online Training')}</h1>

      <div className='ideas-container'>
        {courses.map((course) => (
          <div className={course.active ? "idea" : `idea disabled`} style={{ width: '25%' }}>
            <Link to={`/training/${course.id}`}>
              {
                course.img ?
                  <img src={`${course?.img}`} alt='Idea' /> :
                  <img src={logo} alt='Idea' />
              }

              <div className="info-idea">
                <h2>{t(course?.title)}</h2>
              </div>

            </Link>
          </div>
        ))}

      </div>
    </div>
  )
}

export default Training

