import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import IdeaCard from "./IdeaCard";
import './Home.css';
import axios from 'axios'
import { ArrowRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { InterfaceIdea } from '../../interfaces/app.interfaces';
import { Link } from 'react-router-dom';

type HomeProps = {
    reload: boolean;
};


const Home: FC<HomeProps> = (props) => {
    const { reload } = props

    const { t } = useTranslation();
    const [ideas, setIdeas] = useState<(InterfaceIdea)[]>([]);
    const [loading, setLoading] = useState(false)

    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getData();
    }, [reload, localStorage.getItem('lan')])

    const getData = () => {
        let lan = localStorage.getItem('lan');
        setLoading(true)
        axios.get(`${URL}/idea/getdataideabylanguage/${lan}`)
            .then(res => setIdeas(res?.data))
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    return (
        <div className="home-screen">

            {/*<div className="training-button">*/}
            {/*    <Link to='/training'>*/}
            {/*        <h4 className=''> {t('Online training course')}</h4>*/}
            {/*        <ArrowRightOutlined />*/}
            {/*    </Link>*/}
            {/*</div>*/}

            <h1 className="home-title">{t('Business ideas')}</h1>



            {loading ? <Spin tip="Loading Ideas" size="large" /> :
                <div className="ideas-container">
                    {ideas?.map((_idea: InterfaceIdea) => (
                        <IdeaCard key={_idea.id} idea={_idea} />
                    ))}
                </div>
            }

        </div>
    );
}
export default Home
