import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { InterfaceIdea, InterfaceParamsFormTranslateIdea } from '../../interfaces/app.interfaces'
import { Form, Input, Upload, message, Select, Spin } from 'antd';
import { SwapOutlined, LoadingOutlined } from '@ant-design/icons';
import flagEng from '../../images/flag-english.png'
import flagEsp from '../../images/flag-spanish.png'
import flagPol from '../../images/flag-poland.png'
import flagBulg from '../../images/flag-bulgaria.png'

type FormTranslateIdeaProps = {
    paramsFormTranslateIdea: InterfaceParamsFormTranslateIdea,
    setModalTranslate: any,
    getData: any,
    idOriginal: number
}

const FormTranslateIdea: FC<FormTranslateIdeaProps> = (props) => {
    const { paramsFormTranslateIdea, setModalTranslate, getData, idOriginal } = props
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const URL = process.env.REACT_APP_API_URL;
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const onFinish = (values: any) => {
        values.id = paramsFormTranslateIdea.languageStatus ? dataChecked?.id : 0;
        values.image = "";
        values.createdById = Number(localStorage.getItem('userId'));
        values.language = paramsFormTranslateIdea.languaje;
        values.ideaIdentifier = paramsFormTranslateIdea.ideaIdentifier;
        values.headerImage = infoDataBase?.headerImage;
        values.ImageUrl = infoDataBase?.imageUrl;

        axios.post(`${URL}/idea/posttranslateidea`, values, config)
            .catch(err => console.log(err))
            .finally(() => {
                onReset()
                setModalTranslate(false)
                getData()
            })
    };

    const onReset = () => {
        form.resetFields();
    };

    /*------------------------------------Traer info de la idea base------------------------------------*/

    const [infoDataBase, setInfoDataBase] = useState<InterfaceIdea>();

    useEffect(() => {
        axios.get(`${URL}/idea/${idOriginal}`)
            .then(res => setInfoDataBase(res?.data))
            .catch(err => console.log(err))

    }, [idOriginal, paramsFormTranslateIdea])

    /*--------------------------Llenar la data de las ayudas que ya están traducidas------------------------*/
    const [dataChecked, setDataChecked] = useState<InterfaceIdea>();

    useEffect(() => {
        if (paramsFormTranslateIdea.languageStatus) {
            axios.get(`${URL}/idea/translateidea/${paramsFormTranslateIdea.ideaIdentifier}/${paramsFormTranslateIdea.languaje}`, config)
                .then(res => {
                    setIsLoading(true)
                    setDataChecked(res?.data)
                })
                .catch(err => console.log(err))
                .finally(() => setIsLoading(false))
        } else {
            setDataChecked(undefined)
        }
    }, [paramsFormTranslateIdea])



    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        onReset()
        if (infoDataBase?.language != paramsFormTranslateIdea.languaje) {
            form.setFieldsValue({
                name: dataChecked?.name,
                description: dataChecked?.description,
                whatFor: dataChecked?.whatFor,
                forWhom: dataChecked?.forWhom,
                management: dataChecked?.management,
                value: dataChecked?.value,
                socialImpact: dataChecked?.socialImpact,
                scalability: dataChecked?.scalability,
                sustainability: dataChecked?.sustainability,
                conclution: dataChecked?.conclution
            });
        } else {
            onReset()
        }

    }, [infoDataBase, paramsFormTranslateIdea])

    useEffect(() => {
        axios.get(`${URL}/idea/${idOriginal}`)
            .then(res => setInfoDataBase(res?.data))
            .catch(err => console.log(err))

        console.log(infoDataBase)
        console.log(paramsFormTranslateIdea)

    }, [idOriginal, paramsFormTranslateIdea])

    const defineLenguage = (lang: string) => {
        if (lang == "Es") {
            return (
                <span> <img src={flagEsp} width="24px" /> {t('Spanish')}  </span>
            )
        }
        if (lang == "En") {
            return (
                <span> <img src={flagEng} width="24px" /> {t('English')} </span>
            )
        }
        if (lang == "Pl") {
            return (
                <span> <img src={flagPol} width="24px" /> {t('Polish')} </span>
            )
        }
        if (lang == "Bg") {
            return (
                <span> <img src={flagBulg} width="24px" /> {t('Bulgarian')} </span>
            )
        }
    }


    return (
        <>
            {
                isLoading ?

                    <Spin indicator={antIcon} />
                    :

                    <Form
                        name="translate"
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        {
                            infoDataBase &&

                            <div className="container-translations">
                                <h2>{t('Translate idea')}</h2>

                                <p className="title-translation">
                                    {defineLenguage(infoDataBase?.language)}
                                    <SwapOutlined />
                                    {defineLenguage(paramsFormTranslateIdea?.languaje)}
                                </p>



                                <p>{t('Name of the idea')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.name}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="name" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Description')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.description}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="description" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('What for')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.whatFor}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="whatFor" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('For whom')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.forWhom}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="forWhom" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Management')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.management}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="management" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Value')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.value}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="value" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Social impact')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.socialImpact}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="socialImpact" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Scalability')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.scalability}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="scalability" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Sustainability')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.sustainability}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="sustainability" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <p>{t('Conclusion')}</p>
                                <div className="input-translation">
                                    <div className="input-left">
                                        <p>{infoDataBase?.conclution}</p>
                                    </div>
                                    <SwapOutlined />
                                    <div className="input-right">
                                        <Form.Item name="conclution" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="footer">
                                    <button>
                                        {t('Translate idea')}
                                    </button>
                                </div>

                            </div>
                        }
                    </Form >
            }

        </>
    );
}

export default FormTranslateIdea
