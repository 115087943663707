import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message, Select, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import './FormIdea.css';
import { NotificationEmail } from '../../interfaces/app.interfaces';


type FormIdeaProps = {
    setModalAddNew: any,
    reload: boolean,
    setReload: any,
    setCancelCreate: any;
};

const FormIdea: FC<FormIdeaProps> = (props) => {
    const { setModalAddNew, reload, setReload, setCancelCreate } = props

    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false)
    const URL = process.env.REACT_APP_API_URL
    const { Option } = Select;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    }

    //Función para resetear el formulario
    const onReset = () => {
        form.resetFields();
        setPageNumber(1);

        let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
        let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
        let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
        let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

        for (let i = 0; i < tabHeaders.length; i++) {
            tabHeaders[i].classList.remove('active');
            tabContents[i].classList.remove('active')
            progressBar[i].classList.remove('active')
            checkIcon[i].classList.remove('active')
        }
        tabHeaders[0].classList.add('active');
        tabContents[0].classList.add('active')
    };

    //Función para enviar el formulario
    const onFinish = (values: any) => {
        setLoading(true)
        let indexComa = base64code.indexOf(',');
        values.headerImage = base64code.substring(0, indexComa + 1)
        values.bytesImg = base64code.substring(indexComa + 1)
        values.createdById = Number(localStorage.getItem('userId'))
        values.published = true;
        values.ideaIdentifier = null;

        axios.post(`${URL}/idea`, values, config)
            .then(() => {
                message.success(`${t('Idea created successfully')}`)
                getDatasendEmail(values.createdById, values.name, values.description)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setModalAddNew(false)
                setReload(!reload)
                onReset()
                setLoading(false)
            })
    };

    const getDatasendEmail = (createdById: number, nameIdea: string, descriptionIdea: string) => {
        axios.get(`${URL}/user/getadmins`, config)
            .then(({ data: admins }) => {
                axios.get(`${URL}/user/${createdById}`, config)
                    .then(({ data: userCreator }) => {
                        for (var i = 0; i < admins.length; i++) {
                            let obj: NotificationEmail = {
                                addressTo: admins[i].email,
                                createdBy: userCreator.name,
                                nameIdea,
                                descriptionIdea
                            }
                            sendEmail(obj);
                        }
                    })
            })
    }

    const sendEmail = (data: NotificationEmail) => {
        console.log(data)
        axios.post(`${URL}/idea/sendemail`, data, config)
            .then(res => console.log(res.status))
            .catch(err => console.log(err))
    }

    const onFinishFailed = (errorInfo: any) => {
        messageApi.open({
            type: 'warning',
            content: t('There are fields to complete'),
        });
    };

    //#region -> Convertidor a base 64   
    const [base64code, setBase64code] = useState("");

    const convert2base64 = (e: any) => {
        if (e.size > 2000000) {
            messageApi.info(t('The image exceeded the allowed size (2mb)'))
        } else {
            const filePng = e.file
            let reader = new FileReader()
            reader.onloadend = () => {
                const aux = reader.result?.toString() as string
                setBase64code(aux)
            }
            reader.readAsDataURL(filePng)
        }
    };

    const onChangeUpload = (e: any) => {
        convert2base64(e)
    }
    //#endregion


    //Función para moverse entre pestaña manualmente
    const changeActive = (e: any) => {
        let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
        let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
        let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
        let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

        for (let i = 0; i < tabHeaders.length; i++) {
            tabHeaders[i].classList.remove('active');
            tabContents[i].classList.remove('active')
            progressBar[i].classList.remove('active')
            checkIcon[i].classList.remove('active')
        }

        e.currentTarget.classList.add('active');
        let aux: any;
        for (let i = 0; i < tabHeaders.length; i++) {
            if (tabHeaders[i].className == 'step active') {
                aux = i
            }
        }
        for (let i = 0; i < aux; i++) {
            progressBar[i].classList.add('active')
            checkIcon[i].classList.add('active')
        }
        tabContents[aux].classList.add('active');
        setPageNumber(aux + 1)
    }


    //Función de "Next"
    const nextPage = (e: any) => {
        e.preventDefault();
        // let fieldRequireds = document.querySelectorAll('.tabs-create .tab-content > div .col-required');        

        // for (let i = 0; i < fieldRequireds.length; i++) {
        //     fieldRequireds[i].classList.remove('col-error')
        // }

        let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
        let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
        let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
        let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

        for (let i = 0; i < tabContents.length; i++) {
            tabContents[i].classList.remove('active')
            tabHeaders[i].classList.remove('active');
        }

        if (pageNumber < 4) {
            tabContents[pageNumber].classList.add('active');
            tabHeaders[pageNumber].classList.add('active');
            progressBar[pageNumber - 1].classList.add('active')
            checkIcon[pageNumber - 1].classList.add('active')
        }
        setPageNumber(pageNumber + 1)
    }

    //Función de "Back"
    const backPage = (e: any) => {
        e.preventDefault();

        let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
        let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
        let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
        let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

        for (let i = 0; i < tabContents.length; i++) {
            tabContents[i].classList.remove('active')
            tabHeaders[i].classList.remove('active');
        }

        if (pageNumber > 0) {
            progressBar[pageNumber - 2].classList.remove('active');
            checkIcon[pageNumber - 2].classList.remove('active');
            tabContents[pageNumber - 2].classList.add('active');
            tabHeaders[pageNumber - 2].classList.add('active');
        }
        setPageNumber(pageNumber - 1)
    }

    //Función para cancelar el formulario
    const handleCancelCreateActivate = () => {
        setCancelCreate(true)
        onReset()

        let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
        let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');

        for (let i = 0; i < tabContents.length; i++) {
            tabContents[i].classList.remove('active')
            tabHeaders[i].classList.remove('active')
        }

        tabContents[0].classList.add('active');
        tabHeaders[0].classList.add('active');
        setPageNumber(1)
    };

    useEffect(() => {
        onReset()
    }, [reload]); //Activar con un reseter (?)


    return (
        <>
            {contextHolder}
            <Form
                name="idea"
                form={form}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='form-content-register'>

                    <div className="title">
                        <i className="fa-solid fa-lightbulb"></i>
                        <h2>{t('Add idea')}</h2>
                    </div>

                    <Spin spinning={loading} tip="Loading" size="small" >
                        <div className="tabs-create">

                            <div className="tab-header">
                                <div onClick={changeActive} className="step active">
                                    <div className="bullet">
                                        <span>1</span>
                                    </div>
                                    <div className="check fas fa-check"></div>
                                </div>
                                <div onClick={changeActive} className="step">
                                    <div className="bullet">
                                        <span>2</span>
                                    </div>
                                    <div className="check fas fa-check"></div>
                                </div>
                                <div onClick={changeActive} className="step">
                                    <div className="bullet">
                                        <span>3</span>
                                    </div>
                                    <div className="check fas fa-check"></div>
                                </div>
                                <div onClick={changeActive} className="step">
                                    <div className="bullet">
                                        <span>4</span>
                                    </div>
                                    <div className="check fas fa-check"></div>
                                </div>
                            </div>

                            <div className="tab-content">

                                <div className="active">

                                    <div className="col-general">
                                        <p>{t('Language')}</p>
                                        <Form.Item name="language" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <Select>
                                                <Option key="En" value={"En"}>{t('English')}</Option>
                                                <Option key="Es" value={"Es"}>{t('Spanish')}</Option>
                                                <Option key="Pl" value={"Pl"}>{t('Polish')}</Option>
                                                <Option key="Bg" value={"Bg"}>{t('Bulgarian')}</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>


                                    <div className="col-general">
                                        <p>{t('Name of the idea')}</p>
                                        <Form.Item name="name" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
                                        </Form.Item>
                                    </div>

                                    <div className="col-general">
                                        <p>{t('Description')}</p>
                                        <Form.Item name="description" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
                                        </Form.Item>
                                    </div>
                                </div>



                                <div>
                                    <div className="col-general">
                                        <p>{t('What for')}</p>
                                        <Form.Item name="whatFor" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-general">
                                        <p>{t('For whom')}</p>
                                        <Form.Item name="forWhom" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-general">
                                        <p>{t('Management')}</p>
                                        <Form.Item name="management" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div>
                                    <div className="col-general">
                                        <p>{t('Value')}</p>
                                        <Form.Item name="value" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-general">
                                        <p>{t('Social impact')}</p>
                                        <Form.Item name="socialImpact" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-general">
                                        <p>{t('Scalability')}</p>
                                        <Form.Item name="scalability" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div>
                                    <div className="col-general">
                                        <p>{t('Sustainability')}</p>
                                        <Form.Item name="sustainability" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>

                                    <div className="col-general">
                                        <p>{t('Conclusion')}</p>
                                        <Form.Item name="conclution" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Form.Item>
                                    </div>

                                    <div className="col-general col-upload">
                                        <p>{t('Image')}</p>
                                        <Form.Item
                                            name="image"
                                        >
                                            <Upload
                                                action={`${URL}/idea`}
                                                headers={config.headers}
                                                beforeUpload={e => convert2base64(e)}
                                                onChange={onChangeUpload}
                                                maxCount={1}
                                            >
                                                <Button icon={<UploadOutlined />}>{t('Click to upload')}</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>

                            {/*STEPPER CONTROLLER*/}
                            <div className="stepper-controller">
                                {pageNumber == 1 &&
                                    <Button className="btn-secondary" onClick={handleCancelCreateActivate}>{t('Cancel')}</Button>
                                }

                                {pageNumber > 1 &&
                                    <Button className="btn-secondary" onClick={backPage}> {t('Back')} </Button>
                                }

                                {pageNumber < 4 &&
                                    <Button className="btn-secondary" onClick={nextPage}> {t('Next')} </Button>
                                }

                                {pageNumber == 4 &&
                                    <button>{loading ? <Spin/> : t('Add')}</button>
                                }
                            </div>




                        </div> {/*Tabs create*/}
                    </Spin>
                </div>{/*Form content*/}
            </Form >

        </>

    );
}

export default FormIdea