import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './translation/english.json';
import translationEspanish from './translation/spanish.json';
import translationPolish from './translation/polish.json';
import translationBulgarian from './translation/bulgarian.json';

const resources = {
    En: {
        translation: translationEnglish
    },
    Es: {
        translation: translationEspanish
    },
    Pl: {
        translation: translationPolish
    },
    Bg: {
        translation: translationBulgarian
    }
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: 'En'
    })

export default i18next;