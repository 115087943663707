import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Idea from './components/idea/Idea';
import Home from './components/home/Home';

import NavMenu from './components/nav-bar/NavMenu';
import Footer from './components/footer/Footer';
import './custom.css';
import AdminHome from './components/admin/AdminHome';
import Training from './components/training/Training';
import Course from './components/training/Course';


function App() {

    const [reload, setReload] = useState<boolean>(false);

    localStorage.setItem("lan", "En");

    return (
        <div className='App'>

            <NavMenu reload={reload} setReload={setReload} />

            <main className='main'>
                <Routes>
                    <Route path="/" element={<Home reload={reload} />} />

                    <Route path="/training" element={<Training/>} />
                    <Route path="/training/:id" element={<Course/>} />


                    <Route path="/idea/:id" element={<Idea />} />

                    <Route path="/admin" element={<AdminHome />} />

                </Routes>
            </main>

            <Footer/>
        </div>
    );
}

export default App
