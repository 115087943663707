import { useEffect, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { courses } from './courses';
import { Button, Modal} from 'antd';
import logo from '../home/descarga.png'
import Questionnaire from './test/Questionnaire';
import { useTranslation } from 'react-i18next';

const Course = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const key = Number(id) - 1

  //Verificar si el usuario está logeado
  const [userLogged, setUserLogged] = useState("")
  const changedToken = localStorage.getItem("token") as string // 👈️ non-null assertion

  useEffect(() => {
      setUserLogged(changedToken)
  }, [changedToken])

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
    {
      userLogged ?
      <div className='idea-info'>

        <div className="idea-header">

          <div className="header-left">

            <div className="header-title">
              <Link to={`/training`}> <ArrowLeftOutlined /> </Link>
              {id && <h2>{t(courses[key]?.title)}</h2>}
            </div>

            <div className="header-desc">
              {courses[key].description.map((paragraph, index) => (<p key={index}>{t(paragraph)}</p>))}              
              <ul>
                {courses[key].skills.map((item) => (<li key={item}>{t(item)}</li>))}
              </ul>
            </div>

          </div>

          <div className="header-right">

            <img src={courses[key].img != "" ? courses[key].img : logo} alt='Idea' />

            {id &&
              <div className="course-actions">
                <a href={`/documents/${t("Online training course")} ${Number(id)}.pdf`} download>
                  <Button>{t('Download PDF')}</Button>
                </a>

                <Button onClick={showModal}>{t('Questionnaire')}</Button>
              </div>
            }

          </div>
        </div>


      </div>:
      null
    }
      

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        width={700}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        <Questionnaire setIsModalOpen={setIsModalOpen} questions={courses[key].questionnaire}/>
      </Modal>
    </>
  )
}

export default Course