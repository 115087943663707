import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react'
import '../home/Home.css';
import { Table, Button, Badge, message, Modal, Row, Col, Divider } from 'antd';
import { EditOutlined, WechatOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios'
import { Switch } from 'antd';
import { InterfaceValidationTranslatedIdeas, InterfaceParamsFormTranslateIdea } from '../../interfaces/app.interfaces'
import FormTranslateIdea from './FormTranslateIdea';
import './Admin.css';
import flagEng from '../../images/flag-english.png'
import flagEsp from '../../images/flag-spanish.png'
import flagPol from '../../images/flag-poland.png'
import flagBulg from '../../images/flag-bulgaria.png'

type AdminHomeProps = {

}

const AdminHome: FC<AdminHomeProps> = (props) => {
    const { } = props
    const { t } = useTranslation();
    const URL = process.env.REACT_APP_API_URL;
    const [messageApi, contextHolder] = message.useMessage();
    const [loadingTable, setLoadingTable] = useState(false)
    const [id, setId] = useState<number>(0);
    const [data, setData] = useState<[]>();
    const [modalTranslate, setModalTranslate] = useState<boolean>(false);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }

    const [paramsFormTranslateIdea, setParamsFormTranslateIdea] = useState<InterfaceParamsFormTranslateIdea>({
        ideaIdentifier: '',
        languaje: '',
        languageStatus: false
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: `${t('The status of the idea changed successfully')}`,
        });
    };  

    const getData = () => {
        setLoadingTable(true)
        axios.get(`${URL}/idea/validationtranslated`, config)
            .then(({ data }) => setData(data))
            .catch(err => console.log(err))
            .finally(() => setLoadingTable(false))
    }

    const onChangePublished = (id: number, published: boolean) => {
        let obj = {
            id,
            published
        }
        axios.put(`${URL}/idea/updatepublished/${id}/${published}`, obj, config)
            .then(res => {
                if (res.status === 200) {
                    getData();
                    success();
                }
            }).catch(err => console.log(err))
    }

    const onClickBtnTranslate = (id: number, ideaIdentifier: string, languaje: string, languageStatus: boolean) => {
        setParamsFormTranslateIdea({
            ideaIdentifier,
            languaje,
            languageStatus
        })
        setId(id)
        setModalTranslate(true);
    }

    const onClickBtnDelete = (ideaIdentifier: string) => {
        axios.delete(`${URL}/idea/${ideaIdentifier}`, config)
            .then(() => {
                getData();
                messageApi.open({
                    type: 'success',
                    content: `${t('The idea was removed successfully')}`,
                });
            }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData();
    }, [])

    const columns: ColumnsType<InterfaceValidationTranslatedIdeas> = [
        {
            title: `${t('Published')}`,
            dataIndex: 'published',
            key: 'published',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <>
                    <Switch checked={rowData.published} onChange={(value) => onChangePublished(rowData.id, value)} />
                </>
            )
        },
        { title: `${t('Name of the idea')}`, dataIndex: 'name', key: 'name', },
        {
            title: <img src={flagEng} width="24px" />,
            align: 'center',
            dataIndex: 'en',
            key: 'en',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <span className={rowData.en ? 'translate-green' : 'translate-red'} onClick={() => onClickBtnTranslate(rowData.id, rowData.ideaIdentifier, 'En', rowData.en)}>
                    {rowData.languageIdeaBase === 'En' ? <EditOutlined /> : <WechatOutlined />}
                </span>
            )
        },
        {
            title: <img src={flagEsp} width="24px" />,
            align: 'center',
            dataIndex: 'es',
            key: 'es',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <span className={rowData.es ? 'translate-green' : 'translate-red'} onClick={() => onClickBtnTranslate(rowData.id, rowData.ideaIdentifier, 'Es', rowData.es)}>
                    {rowData.languageIdeaBase === 'Es' ? <EditOutlined /> : <WechatOutlined />}
                </span>
            )
        },
        {
            title: <img src={flagPol} width="24px" />,
            align: 'center',
            dataIndex: 'pl',
            key: 'pl',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <span className={rowData.pl ? 'translate-green' : 'translate-red'} onClick={() => onClickBtnTranslate(rowData.id, rowData.ideaIdentifier, 'Pl', rowData.pl)}>
                    {rowData.languageIdeaBase === 'Pl' ? <EditOutlined /> : <WechatOutlined />}
                </span>
            )
        },
        {
            title: <img src={flagBulg} width="24px" />,
            align: 'center',
            dataIndex: 'bg',
            key: 'bg',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <span className={rowData.bg ? 'translate-green' : 'translate-red'} onClick={() => onClickBtnTranslate(rowData.id, rowData.ideaIdentifier, 'Bg', rowData.bg)}>
                    {rowData.languageIdeaBase === 'Bg' ? <EditOutlined /> : <WechatOutlined />}
                </span>
            )
        },
        {
            title: `${t('Delete')}`,
            align: 'center',
            dataIndex: 'delete',
            key: 'delete',
            render: (_: any, rowData: InterfaceValidationTranslatedIdeas) => (
                <span className={'translate-delete'} onClick={() => onClickBtnDelete(rowData.ideaIdentifier)}>
                    <DeleteOutlined />
                </span>
            )
        }
    ];

    return (
        <div className="home-screen">
            {contextHolder}
            <Table columns={columns} dataSource={data} rowKey={e => e.id} loading={loadingTable}/>

            <Modal
                open={modalTranslate}
                footer={null}
                onOk={() => setModalTranslate(false)}
                onCancel={() => setModalTranslate(false)}
                centered
                width={800}
                style={{ marginTop: '25px', marginBottom: '25px' }}
            >
                <FormTranslateIdea
                    paramsFormTranslateIdea={paramsFormTranslateIdea}
                    setModalTranslate={setModalTranslate}
                    getData={getData}
                    idOriginal={id}
                />
            </Modal>

        </div>

    );
}

export default AdminHome