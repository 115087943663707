import { useTranslation } from 'react-i18next';
import React, { FC, useState, useEffect } from 'react';
import { Checkbox, Form, Input } from 'antd';
import './FormAuth.css';
import axios from 'axios'

type FormLoginProps = {
    setIsLogin: any,
    setModalLogin: any,
    modalLogin: boolean,
    isLogin: boolean
}

const FormLogin: FC<FormLoginProps> = (props) => {
    const { setIsLogin, setModalLogin, modalLogin, isLogin } = props

    const { t } = useTranslation();
    const URL = process.env.REACT_APP_API_URL

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        axios.post(`${URL}/login`, values)
            .then(({ data }) => {
                localStorage.setItem('role', data.role)
                localStorage.setItem('token', data.token)
                localStorage.setItem('userId', data.userId)
                setModalLogin(false)
                onReset()
            })
            .catch(err => {
                localStorage.removeItem('role')
                localStorage.removeItem('token')
                localStorage.removeItem('userId')
                setInvalidLogin(true)
            })
            .finally(() => onReset())
    };

    const [invalidLogin, setInvalidLogin] = useState(false);

    const onFinishFailed = (errorInfo: any) => {
        setInvalidLogin(true)
        onReset()
    };

    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        onReset()
    }, [modalLogin, isLogin]); //Activar con un reseter (?)

    return (
        <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className='form-content'>

                <i className="fa-solid fa-user-lock"></i>

                <h2>{t('Log in')}</h2>

                {invalidLogin && <p style={{ color: 'red', textAlign: 'center' }}>{t('User or password do not match')}</p>}

                <p>{t('Username')}</p>
                <Form.Item name="username" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                    <Input />
                </Form.Item>

                <p>{t('Password')}</p>
                <Form.Item name="password" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>{t('Remember me')}</Checkbox>
                </Form.Item>

                <button>{t('Log in')}</button>

                <a style={{ textAlign: 'center' }} onClick={() => setIsLogin(false)}>{t("Don't have an account")}</a>

            </div>
        </Form>
    );
}

export default FormLogin