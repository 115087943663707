import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { Button, Form, Radio, Space } from 'antd';
import './Questionnaire.css';

type QuestionnaireProps = {
  setIsModalOpen?: any,
  questions: any
};

const Questionnaire: FC<QuestionnaireProps> = (props) => {
  const { setIsModalOpen, questions } = props
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [form] = Form.useForm();

  const [screenAnswers, setScreenAnswers] = useState(false)
  const [corrects, setCorrects] = useState(0)
  const [correctAnswers, setCorrectAnswers] = useState(false)

  //Función para resetear el formulario
  const onReset = () => {
    form.resetFields();

    setScreenAnswers(false)
    setCorrectAnswers(false)
    setCorrects(0)
    setPageNumber(1);

    setTimeout(() => {
      let tabHeaders = document.querySelectorAll('.tab-header > div');
      let tabContents = document.querySelectorAll('.tab-content > div');
      tabHeaders[0]?.classList.add('active');
      tabContents[0]?.classList.add('active');
    }, 200);

  };

  //Función para enviar el formulario
  const onFinish = (values: any) => {
    let score = 0;
    for (let i = 0; i < questions.length; i++) {
      if (values[i + 1] == questions[i].response) {
        score += 1;
      }
    }
    setCorrects(score)
    setScreenAnswers(true)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo)
  };

  //Función para moverse entre pestaña manualmente
  const changeActive = (e: any) => {
    let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
    let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
    let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
    let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

    for (let i = 0; i < tabHeaders.length; i++) {
      tabHeaders[i].classList.remove('active');
      tabContents[i].classList.remove('active')
      progressBar[i].classList.remove('active')
      checkIcon[i].classList.remove('active')
    }

    e.currentTarget.classList.add('active');
    let aux: any;
    for (let i = 0; i < tabHeaders.length; i++) {
      if (tabHeaders[i].className == 'step active') {
        aux = i
      }
    }
    for (let i = 0; i < aux; i++) {
      progressBar[i].classList.add('active')
      checkIcon[i].classList.add('active')
    }
    tabContents[aux].classList.add('active');
    setPageNumber(aux + 1)
  }


  //Función de "Next"
  const nextPage = (e: any) => {
    e.preventDefault();

    let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
    let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
    let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
    let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

    for (let i = 0; i < tabContents.length; i++) {
      if (tabContents[i] && tabContents[i].classList.contains('active')) {
        tabContents[i].classList.remove('active');
      }
      if (tabHeaders[i] && tabHeaders[i].classList.contains('active')) {
        tabHeaders[i].classList.remove('active');
      }
    }

    if (pageNumber < questions?.length) {
      tabContents[pageNumber].classList.add('active');
      tabHeaders[pageNumber].classList.add('active');
      progressBar[pageNumber - 1].classList.add('active')
      checkIcon[pageNumber - 1].classList.add('active')
    }
    setPageNumber(pageNumber + 1)
  }

  //Función de "Back"
  const backPage = (e: any) => {
    e.preventDefault();

    let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
    let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');
    let progressBar = document.querySelectorAll('.tabs-create .tab-header .step .bullet');
    let checkIcon = document.querySelectorAll('.tabs-create .tab-header .step .check');

    for (let i = 0; i < tabContents.length; i++) {
      if (tabContents[i] && tabContents[i].classList.contains('active')) {
        tabContents[i].classList.remove('active');
      }
      if (tabHeaders[i] && tabHeaders[i].classList.contains('active')) {
        tabHeaders[i].classList.remove('active');
      }
    }

    if (pageNumber > 0) {
      progressBar[pageNumber - 2].classList.remove('active');
      checkIcon[pageNumber - 2].classList.remove('active');
      tabContents[pageNumber - 2].classList.add('active');
      tabHeaders[pageNumber - 2].classList.add('active');
    }
    setPageNumber(pageNumber - 1)
  }

  //Función para cancelar el formulario
  const handleCancelCreateActivate = () => {
    setIsModalOpen(false)
    onReset()

    let tabContents = document.querySelectorAll('.tabs-create .tab-content > div');
    let tabHeaders = document.querySelectorAll('.tabs-create .tab-header > div');

    for (let i = 0; i < tabContents.length; i++) {
      tabContents[i].classList.remove('active')
      tabHeaders[i].classList.remove('active')
    }

    tabContents[0].classList.add('active');
    tabHeaders[0].classList.add('active');
    setPageNumber(1)
  };

  //Función para ver respuestas
  const viewAnswers = () => {
    setCorrectAnswers(true)
    setPageNumber(1);
    setScreenAnswers(false)

    setTimeout(() => {
      let tabHeaders = document.querySelectorAll('.tab-header > div');
      let tabContents = document.querySelectorAll('.tab-content > div');
      tabHeaders[0]?.classList.add('active');
      tabContents[0]?.classList.add('active');
    }, 200);
  }

  useEffect(() => {
    onReset()
  }, []);


  return (
    <>
      <Form
        name="questionnaire"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {!screenAnswers ?
          <div className='form-content-register'>
            <div className="title">
              <i className="fa-solid fa-lightbulb"></i>
              <h2>{t('Questionnaire')}</h2>
            </div>

            <div className="tabs-create" style={{ height: 'auto' }}>

              <div className="tab-header">
                {questions.map((x: any, index: number) => (
                  <div onClick={changeActive} className="step" key={x.id}>
                    <div className="bullet">
                      <span>{index + 1}</span>
                    </div>
                    <div className="check fas fa-check"></div>
                  </div>
                ))}
              </div>

              <div className="tab-content">
                {questions.map((ask: any) => (
                  <div key={ask.id} className='ac'>
                    <div className="col-general">
                      <p>{t(ask.question)}</p>
                      <Form.Item name={ask.id}>
                        <Radio.Group>
                          <Space direction="vertical">
                            {ask.options.map((opc: string, index: number) => {
                              if (ask.response == index + 1 && correctAnswers)
                                return <Radio key={opc} value={index + 1} style={{ background: 'green', color: 'white', borderRadius: 20 }}>{t(opc)}</Radio>
                              else {
                                return <Radio key={opc} value={index + 1}>{t(opc)}</Radio>
                              }
                            })}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                ))}
              </div>

              {/*STEPPER CONTROLLER*/}
              <div className="stepper-controller">
                {pageNumber == 1 &&
                  <Button
                    className="btn-secondary"
                    onClick={handleCancelCreateActivate}>
                    {t('Cancel')}
                  </Button>}

                {pageNumber > 1 &&
                  <Button
                    className="btn-secondary"
                    onClick={backPage}>
                    {t('Back')}
                  </Button>}

                {pageNumber < questions?.length &&
                  <Button
                    className="btn-secondary"
                    onClick={nextPage}>
                    {t('Next')}
                  </Button>}

                {pageNumber == questions?.length && (correctAnswers ?
                  <button onClick={() => setScreenAnswers(true)}>{t('Result')}</button> :
                  <button>{t('Submit')}</button>
                )}
              </div>


            </div>
          </div>

          :
          <div className="form-content-register">
            <div className="title">
              <i className="fa-solid fa-graduation-cap"></i>
              <h2>{t('Result')}</h2>
            </div>

            <h3 style={{ textAlign: 'center' }}>{t('Correct answers')}: {corrects} / {questions.length}</h3>

            <div className="wrapper-result">
              <Button onClick={() => onReset()}>{t('Reset')}</Button>
              <Button onClick={viewAnswers}>{t('See answers')}</Button>
            </div>
          </div>

        }
      </Form >

    </>

  );
}

export default Questionnaire