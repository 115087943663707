import React, { FC, useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Spin, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';

type IdeaInfoProps = {
  id: number
  nameIdea: string,
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const FormInformation: FC<IdeaInfoProps> = (props) => {
  const { id, nameIdea, } = props
  const { t } = useTranslation();
  const URL = process.env.REACT_APP_API_URL;

  const [isSending, setIsIsending] = useState(false)

  const [formSucess, setFormSucess] = useState(false)
  const [formError, setFormError] = useState(false)
  const [messageError, setMessageError] = useState()

  const onFinish = (values: any) => {
    setIsIsending(true)

    axios.post(`${URL}/idea/email`, {
      "Receiver": "luis.jimenez@fi-group.com",
      "Subject": `Request for information about the idea: ${nameIdea}`,
      "Message": `<h3>Request for information about the idea: ${nameIdea} (${id})</h3>  <p><strong>Name: </strong>${values.name}</p>  <p><strong>Email: </strong>${values.email}</p>  <p><strong>Message: </strong>${values.message}</p>  <p><strong>Note: </strong>${values.remember ? "I need the mentoring plan of the idea" : null}</p>`
    })
      .then((res) => {
        setIsIsending(false)
        setFormSucess(true)

      })
      .catch(err => {
        setIsIsending(false)
        setFormError(true)
        setMessageError(err.response.data.title);
      })
      .finally(() => {
        setTimeout(() => {
          setFormSucess(false);
          setFormError(false)
        }, 6000);
      })
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsIsending(false)
    setMessageError(errorInfo);

    setTimeout(() => {
      setFormSucess(false);
      setFormError(false)
    }, 6000);
  };

  useEffect(() => {
  }, [])



  return (
    <>
      {!isSending ?
        <>
          {formSucess ? <Alert message={t("The form was sent successfully")} type="success" /> :
            <>
              {formError && <Alert message={messageError} type="error" />}
              <Form
                name="form-info-idea"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("Email")}
                  name="email"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("Message")}
                  name="message"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>{t("Do you want to request the mentoring plan for this idea?")}</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    {t("Submit")}
                  </Button>
                </Form.Item>
              </Form>
            </>
          }
        </> :
        <Spin indicator={antIcon} />
      }
    </>
  )
}

export default FormInformation