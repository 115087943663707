import module1 from '../../images/module1-agile-methodology.jpg'
import module2 from '../../images/module2-personnel-management.jpg'
import module3 from '../../images/module3-business-strategy-&-marketing.jpg'
import module4 from '../../images/module4-public-procurement.jpg'
import module5 from '../../images/module5-finances.jpg'

export const courses = [
    {
        id: "1",
        active: true,
        title: "Module 1: Introduction to agile methodologies for business models design",
        img: module1,
        description: [
            "This training curriculum is based on agile business methodologies. In this first part of the training course, entrepreneurs will explore those methods to set up their own strategies during the mentoring and teamwork sessions.",
            "Completing the module will allow you to:"
        ],
        skills: [
            "to get to know current methods and tools to help them in designing their business strategy.",
            "to identify and analyze needs and to provide adequate solutions.",
            "to be able to adjust a business idea in front of realities.",
            "to plan their business idea and to be able to explain it."
        ],
        questionnaire: [
            {
                id: 1,
                question: "Design thinking is a human-centered approach to problem-solving that allows for both divergent and convergent thinking styles.",
                options: [
                    "True",
                    "False"
                ],
                response: 1
            },
            {
                id: 2,
                question: "Which step of the design thinking process is associated with divergent thinking?",
                options: [
                    "Step 1: Empathizing",
                    "Step 2: Defining",
                    "Step 3: Getting ideas",
                    "Step 4: Prototyping",
                    "Step 5: Testing",
                    "Step 6: Assessing"
                ],
                response: 3
            },
            {
                id: 3,
                question: "Which of the following is NOT one of the nine key elements of a business model in the Business Model Canvas innovation tool?",
                options: [
                    "Customer Segments",
                    "Value Proposition",
                    "Channels",
                    "Customer Relationships",
                    "Key Resources",
                    "Key Activities",
                    "Key Partners",
                    "Revenue Streams",
                    "Cost Structure",
                    "None of the above"
                ],
                response: 10
            },
            {
                id: 4,
                question: "The lean startup methodology seeks to increase wasteful practices during the earliest phases of a company.",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
            {
                id: 5,
                question: "What is the main purpose of the lean startup methodology?",
                options: [
                    "To increase wasteful practices during the earliest phases of a company",
                    "To require large amounts of outside funding, elaborate business plans, or a perfect product",
                    "To tailor the product or service to the specific needs of its customers",
                    "To assess consumers' specific demands and how to meet that demand using the least amount of resources possible"
                ],
                response: 4
            },
        ]
    },
    {
        id: "2",
        active: true,
        title: "Module 2: Business strategy and marketing plan",
        img: module2,
        description: [
            "Based on the agile methodologies, students will work on designing their own business strategy and marketing plans. Those training sessions will provide theoretical schemes and concrete examples on structuring their business ideas and testing them according to market needs and challenges. The development of those business and marketing strategies might be weekly reviewed by mentors.",
            "Completing the module will allow you to:"
        ],
        skills: [
            "to be able to design a business plan based on Design Thinking and Lean startup methodologies.",
            "to acquire competencies to test and to validate hypothesis related to market needs and value propositions.",
            "to plan marketing strategies adapted to resources available."
        ],
        questionnaire: [
            {
                id: 1,
                question: "A strategy is the selected process through which a specific future state is expected to be reached.",
                options: [
                    "True",
                    "False"
                ],
                response: 1
            },
            {
                id: 2,
                question: "Which of the following is NOT a learning goal of Module 2?",
                options: [
                    "To be able to design a business plan based on Design Thinking and Lean startup methodologies",
                    "To acquire competencies to test and to validate hypothesis related to market needs and value propositions",
                    "To plan marketing strategies adapted to resources available",
                    "To learn how to manage finances and accounting"
                ],
                response: 4
            },
            {
                id: 3,
                question: "Which of the following is a reason why hypothesis validation is important?",
                options: [
                    "Entrepreneurs are managing a business and making it profitable in an environment of uncertainty: validating quickly and cheaply is essential",
                    "A business plan must always be validated",
                    "The Canvas does not reflect contact with customers",
                    "All of the above"
                ],
                response: 1
            },
            {
                id: 4,
                question: "The SWOT analysis is used to detect the opportunities and threats that a company faces, as well as the strengths and weaknesses it has.",
                options: [
                    "True",
                    "False"
                ],
                response: 1
            },
            {
                id: 5,
                question: "What is NOT the main reason for failure when developing businesses and services?",
                options: [
                    "Lack of funding",
                    "Lack of marketing strategies",
                    "Developing businesses and services that nobody finally needs",
                    "Poor management"
                ],
                response: 3
            },
        ]
    },
    {
        id: "3",
        active: true,
        title: "Module 3: Personnel management",
        img: module3,
        description: [
            "Human resources are the people working in an organization, including the directors and the executives. For every organization, it is necessary to have the capital that allows it to carry out its activities; this capital includes both cash and goods, but employees are the most important element in the production and the flow of the capital within the organization.",
            "Nowadays, the business sector is defined by fast changes and instability, and all firms need to be properly prepared to cope with these changes and manage them in order to succeed.In this module, we are going to examine the different aspects of human resources and how to manage them properly in order to make your business thrive.",
            "In this module you will learn:"
        ],
        skills: [
            "Definition and Importance of the Human Resources Department.",
            "Which are the most essential skills for Human Resources.",
            "How to build a strong HR strategy.",
            "How to establish a CSR culture in your business."
        ],
        questionnaire: [
            {
                id: 1,
                question: "What is the most important element of an organization?",
                options: [
                    "Money",
                    "Employees",
                    "Products",
                    "Goods"
                ],
                response: 2
            },
            {
                id: 2,
                question: "How many are the roles of the HR Department?",
                options: [
                    "Only 1",
                    "4",
                    "5",
                    "7"
                ],
                response: 3
            },
            {
                id: 3,
                question: "HR is responsible for the recruitment of the staff.",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
            {
                id: 4,
                question: "Taking up an HR position requires a bachelor's degree in the specific field.",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
            {
                id: 5,
                question: "The HR Department should always go on the side of the directors.",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
        ]
    },
    {
        id: "4",
        active: true,
        title: "Module 4: Public procurement",
        img: module4,
        description: [
            "This module provides comprehensive information on public procurement, including its principles, procedures, and legal framework, which are essential for individuals and organizations engaging in public procurement activities.",
            "Completing the module will allow you to:"
        ],
        skills: [
            "To understand the fundamental principles, importance and goals of public procurement.",
            "To familiarize with the types of procurement procedures.",
            "To acquire knowledge of the public procurement framework in the EU.",
            "To be aware of the public procurement landscape in Spain, Poland, Bulgaria and Ireland."
        ],
        questionnaire: [
            {
                id: 1,
                question: "What are the fundamental principles of public procurement in the EU?",
                options: [
                    "Transparency, competition, and efficiency",
                    "Confidentiality, exclusivity, and flexibility",
                    "Openness, monopoly, and cost-effectiveness",
                    "Secrecy, restraint, and economy"
                ],
                response: 1
            },
            {
                id: 2,
                question: "Which of the following is NOT a type of public procurement procedure?",
                options: [
                    "Open procedure",
                    "Restricted procedure",
                    "Competitive negotiation procedure",
                    "Confidential procedure"
                ],
                response: 4
            },
            {
                id: 3,
                question: "The Public Procurement Act in Bulgaria defines the conditions and procedures for awarding public procurements for public works, supply, or services.",
                options: [
                    "True",
                    "False"
                ],
                response: 1
            },
            {
                id: 4,
                question: "Public procurement in Spain constitutes approximately 20% of the country's Gross Domestic Product (GDP).",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
            {
                id: 5,
                question: "National advertising thresholds for works contracts in Ireland, as of January 1, 2022, are \u20AC2.500.000 for Government Departments and Offices.",
                options: [
                    "True",
                    "False"
                ],
                response: 2
            },
        ]
    },
    {
        id: "5",
        active: true,
        title: "Module 5: Finances",
        img: module5,
        description: [
            "A financial plan is simply an overview of your current business financials and projections for growth. Think of any documents that represent your current monetary situation as a snapshot of the health of your business and the projections being your future expectations.",
            "Financial planning allows entrepreneurs to estimate the quantity and the timing of money needed to start the business and to keep it running.It might help the entrepreneurs to answer the following questions: is it worth investing time and money in this business ? What is the cash burn rate? How to minimize dilution by external investors? Scenario analysis and contingency plan?",
            "When entrepreneurship is made by persons at risk of exclusion, finances is often a critical dimension to take into account.Financial aspect could become a stress factor for the new entrepreneurs, and it is crucial to bring support and relevant advice taking into account each person's specific situation.",
            "After completing this module, the learner user:"
        ],
        skills: [
            "He / She will be able to draft a financial plan and adapt it within the whole business plan.",
            "He / She knows the main concepts and methods for an efficient financial plan.",
            "He / She will be able to sustain a financial plan."
        ],
        questionnaire: [
            {
                id: 1,
                question: "What is a financial plan?",
                options: [
                    "A snapshot of the health of your business",
                    "A list of all the necessary expenditures to keep your small business growing",
                    "An overview of your current business financials and projections for growth",
                    "A guideline for how to manage your costs"
                ],
                response: 3
            },
            {
                id: 2,
                question: "Why is a financial plan important for small businesses?",
                options: [
                    "It's a reminder of what your goals are and what you're trying to achieve",
                    "It lays out what your possible costs are and how to manage them",
                    "Investors, bankers, and creditors won't meet with you without one",
                    "All of the above"
                ],
                response: 4
            },
            {
                id: 3,
                question: "What are the three key characteristics of a successful entrepreneur from a financial perspective?",
                options: [
                    "Risk-taking, strategic planning, and effective communication",
                    "Aggressiveness, clarity, and precision",
                    "Risk-taking, strategic planning, and clarity",
                    "Aggressiveness, strategic planning, and effective communication"
                ],
                response: 1
            },
            {
                id: 4,
                question: "What is net working capital?",
                options: [
                    "The amount of money you have left over after paying all your bills",
                    "Accounts receivable plus inventory minus accounts payable",
                    "The amount of money you have available to invest in new assets",
                    "The amount of money you owe to your suppliers"
                ],
                response: 2
            },
            {
                id: 5,
                question: "What are some examples of financing options for entrepreneurs?",
                options: [
                    "National or regional grants, microcredits, and venture capital",
                    "Crowdfunding, foundations, and commercial credit",
                    "National or regional grants, microcredits, and crowdfunding",
                    "Venture capital, foundations, and commercial credit"
                ],
                response: 1
            },
        ]
    },
]