import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import FormLogin from '../authentication/FormLogin'
import FormRegister from '../authentication/FormRegister'
import { Button, Modal } from 'antd'
import FormIdea from '../home/FormIdea';
import logo from '../../images/logo.jpg'
import './NavMenu.css';
import { Select } from 'antd';
import flagEng from '../../images/flag-english.png'
import flagEsp from '../../images/flag-spanish.png'
import flagPol from '../../images/flag-poland.png'
import flagBulg from '../../images/flag-bulgaria.png'

type NavMenuProps = {
    reload: boolean,
    setReload: any;
};

const NavMenu: FC<NavMenuProps> = (props) => {
    const { reload, setReload } = props

    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    const role = localStorage.getItem('role');

    //Mostrar y ocultar toggle desplegable
    const changeTogleNavbar = () => {
        setToggleNavbar(!toggleNavbar)
    }
    const [toggleNavbar, setToggleNavbar] = useState<boolean>(false);


    //Mostrar y ocultar modales
    const [modalLogin, setModalLogin] = useState<boolean>(false);
    const [modalAddNew, setModalAddNew] = useState<boolean>(false);

    const closeModalUser = () => {
        setIsLogin(true)
        setModalLogin(false)
    }

    //Mostrar y ocultar (Form Register / Form Login)
    const [isLogin, setIsLogin] = useState<boolean>(true);


    //Verificar si el usuario está logeado
    const [userLogged, setUserLogged] = useState("")
    const changedToken = localStorage.getItem("token") as string // 👈️ non-null assertion

    useEffect(() => {
        setUserLogged(changedToken)
    }, [changedToken])


    //Cerrar el modal de "Idea" por medio de props
    const [cancelCreate, setCancelCreate] = useState<boolean>(false);

    const handleCancelCreate = () => {
        setModalAddNew(false);
        setCancelCreate(false)
    };

    useEffect(() => {
        if (cancelCreate) {
            handleCancelCreate();
        }
    }, [cancelCreate]);

    useEffect(() => {
        setReload(!reload)
    }, [modalAddNew]);

    const handleChange = (value: string) => {
        localStorage.setItem("lan", value);
        i18n.changeLanguage(value);
    };


    return (
        <header className="header">

            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>

                <NavbarToggler onClick={changeTogleNavbar} className="mr-2" />

                <Link to={`/`}>
                    <img className="logo-main" src={logo} alt='Idea' />
                </Link>

                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!toggleNavbar} navbar>
                    <ul className="navbar-nav flex-grow">
                        <>
                            {userLogged ?
                                <>
                                    {
                                        role === 'Admin' ?
                                            <Button type="primary" onClick={() => navigate('/admin')}>
                                                {t('Administrator')}
                                            </Button>
                                            : null

                                    }
                                    {
                                            <Button type="primary" onClick={() => navigate('/training')}>
                                                {t('Online training course')}
                                            </Button>

                                    }
                                    {
                                        role === 'Mentor' || role === 'Both' || role === 'Admin' ?
                                            <Button type="primary" onClick={() => setModalAddNew(true)}>
                                                {t('Add idea')}
                                            </Button>
                                            : null

                                    }

                                    <Button type="primary" onClick={() => {
                                        setUserLogged("")
                                        localStorage.removeItem('role')
                                        localStorage.removeItem('token')
                                        localStorage.removeItem('userId')
                                        navigate('/')
                                    }}>
                                        {t('Log out')}
                                    </Button>



                                </> :
                                <>

                                    <Button type="primary" onClick={() => setModalLogin(true)}>
                                        {t('Log in')}
                                    </Button>
                                </>

                            }

                            <Select
                                defaultValue='En'
                                style={{ width: 75 }}
                                onChange={handleChange}
                                options={[
                                    { value: 'En', label:  <img src={flagEng} width="24px"/> },
                                    { value: 'Es', label:  <img src={flagEsp} width="24px"/> },
                                    { value: 'Pl', label:  <img src={flagPol} width="24px"/> },
                                    { value: 'Bg', label:  <img src={flagBulg} width="24px"/> },
                                ]}
                            />
                        </>
                    </ul>
                </Collapse>

                <Modal
                    open={modalAddNew}
                    footer={null}
                    onOk={() => {
                        setModalAddNew(false)
                    }}
                    onCancel={() => setModalAddNew(false)}
                    centered
                    width={700}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                    <FormIdea setModalAddNew={setModalAddNew} reload={reload} setReload={setReload} setCancelCreate={setCancelCreate} />
                </Modal>

                {isLogin ?
                    <Modal
                        open={modalLogin}
                        footer={null}
                        onCancel={() => closeModalUser()}
                        centered
                        width={350}
                    >
                        <FormLogin setIsLogin={setIsLogin} setModalLogin={setModalLogin} modalLogin={modalLogin} isLogin={isLogin} />
                    </Modal> :

                    <Modal
                        open={modalLogin}
                        footer={null}
                        onCancel={() => closeModalUser()}
                        centered
                        width={600}
                    >
                        <FormRegister setIsLogin={setIsLogin} setModalLogin={setModalLogin} />
                    </Modal>
                }
            </Navbar>
        </header>
    );
}
export default NavMenu