import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { InterfaceIdea } from '../../interfaces/app.interfaces';
import logo from './descarga.png'
import './Home.css';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0, });
    useEffect(() => {
        function handleResize() { setWindowSize({ width: window.innerWidth, height: window.innerHeight, }); }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

type IdeaProps = {
    idea: InterfaceIdea;
};

const IdeaCard: FC<IdeaProps> = (props) => {
    const { idea } = props

    const { t } = useTranslation();
    const size = useWindowSize();

    const cutNumber = (n: number) => {
        if (size.width > 2400 / 2) return 100 * n;
        if (size.width > 2000 / 2) return 80 * n;
        if (size.width > 1850 / 2) return 70 * n;
        if (size.width > 1600 / 2) return 60 * n;
        if (size.width > 1450 / 2) return 40 * n;
        if (size.width > 1200 / 2) return 20 * n;
        return 10 * n;
    };

    const getLabel = (lan: string): string => {
        if (lan === localStorage.getItem('lan')) {
            return `${t('Translation found')}`;
        } else {
            return `${t('Translation not found')}`;
        }
    }

    return (
        <div className="idea">
            <Link to={`/idea/${idea.ideaIdentifier}`}>
                {idea.bytesImg ?
                    <img src={`${idea?.headerImage}${idea?.bytesImg}`} alt='Idea' /> 
                    :
                    <img src={logo} alt='Idea' />
                }

                <div className="info-idea">
                    <h2>{idea?.name?.replace('\n', '').slice(0, cutNumber(0.6)) + (idea.name?.length > cutNumber(0.6) ? '...' : '')}</h2>
                    <p>{idea?.description?.replace('\n', '').slice(0, cutNumber(1)) + (idea.description?.length > cutNumber(1) ? '...' : '')}</p>
                </div>

            </Link>
        </div >
    );
}

export default IdeaCard