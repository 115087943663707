import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Select, message } from 'antd';
import './FormAuth.css';
import axios from 'axios'
import { InterfaceCountry } from '../../interfaces/app.interfaces';


const { Option } = Select;

type FormRegisterProps = {
    setIsLogin: any,
    setModalLogin: any
}

const FormRegister: FC<FormRegisterProps> = (props) => {

    const { setIsLogin, setModalLogin } = props

    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();

    const URL = process.env.REACT_APP_API_URL

    const onFinish = (values: any) => {

        const valuesLogin = {
            "username": values.email,
            "password": values.password
        }

        axios.get(`${URL}/user/byemail/${values.email}`)
            .then(({ data }) => {
                if (data) {
                    messageApi.info(`${t('User Already Exists')}`);
                } else {
                    insertUser(values, valuesLogin)
                }
            })
            .catch(err => console.log(err))
    };

    const insertUser = (values: any, valuesLogin: any) => {
        axios.post(`${URL}/user`, values)
            .then(res => {
                insertUserRoles(res?.data.id, values.role)
            })
            .catch(err => console.log(err))
            .finally(() => {
                axios.post(`${URL}/login`, valuesLogin)
                    .then(({ data }) => {
                        localStorage.setItem('role', data.role)
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('userId', data.userId)
                        setModalLogin(false)
                    })
                    .catch(err => {
                        localStorage.removeItem('role')
                        localStorage.removeItem('token')
                        localStorage.removeItem('userId')
                    })
                    .finally(() => {
                        setModalLogin(false)
                        setIsLogin(true)
                    })
            })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo)
    };

    const insertUserRoles = (userId: number, role: number) => {
        axios.post(`${URL}/userRoles`, { userId: userId, roleId: role })
            .catch(err => console.log(err))
    }

    //Llenar el select de los paises
    const [countries, setCountries] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        axios.get(`${URL}/country`)
            .then(res => setCountries(res?.data))
            .catch(err => console.log(err))

        axios.get(`${URL}/role`)
            .then(res => setRoles(res?.data))
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='form-content'>

                    <i className="fa-solid fa-user-plus"></i>

                    <h2>{t('Register')}</h2>

                    <p>{t('Name')}</p>
                    <Form.Item name="name" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                        <Input />
                    </Form.Item>

                    <p>{t('Email')}</p>
                    <Form.Item name="email" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                        <Input />
                    </Form.Item>

                    <p>{t('Password')}</p>
                    <Form.Item name="password" rules={[{ required: true, message: `${t('Enter a password')}`, },]} hasFeedback>
                        <Input.Password />
                    </Form.Item>

                    <p>{t('Confirm Password')}</p>
                    <Form.Item name="confirmPassword" dependencies={['password']} hasFeedback
                        rules={[{ required: true, message: `${t('Confirm your password')}`, },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(`${t('Passwords do not match')}`));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <p>{t('Country')}</p>
                    <Form.Item name="countryId" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                        <Select>
                            {
                                countries?.map((country: InterfaceCountry) => (
                                    <Option key={country?.id} value={country?.id}>{t(`${country?.name}`)}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <p>{t('Role')}</p>
                    <Form.Item name="role" rules={[{ required: true, message: `${t('This field is required')}`, },]}>
                        <Select>
                            {
                                roles?.map((role: InterfaceCountry) => (
                                    role?.name !== "Admin" ?
                                        <Option key={role?.id} value={role?.id}>{t(`${role?.name}`)}</Option>
                                    : null
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <button>{t('Register')}</button>
                    <a style={{ textAlign: 'center' }} onClick={() => setIsLogin(true)}>{t('Do you have an account')}</a>

                </div>
            </Form>
        </>
    );
}
export default FormRegister